import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { Attachment, Group as GroupClass, Segment, useEditor, useGroup, useSegment, useManager } from 'entities/sketch/Manager';
import { ReactComponent as DeleteSvg } from './icons/delete.svg';
import { ReactComponent as Checkbox } from './icons/checkbox.svg';
import { ReactComponent as AttachSvg } from './icons/attach.svg';
import { ReactComponent as DropSvg } from './icons/drop.svg';
import { ReactComponent as DropAttachSvg } from './icons/drop-attach.svg';
import { ReactComponent as AddAttachSvg } from './icons/add_attach.svg';
import Button from 'Components/Button';
import AttachmentComponent from '../Attachment';
import { setElementForAttach } from 'entities/library';
import useClickOutside from 'services/useClickOutside';
import useChildElementsHeight from 'services/useChildElementsHeight';
import { useHotKey } from 'hooks';
import { Notifications, setNotification } from 'entities/notification';
import { useStore } from 'effector-react';
import { $tutorial } from 'entities/tutorial';
import { setHint } from 'entities/hint';
import './Group.scss';


type GroupProps = {
    group: GroupClass;
    index: number;
    realIndex?: number;
};

type ApprovedSegment = {
    segment: Segment;
    isApproved: boolean;
}

export function EditingGroup({ group, index, hovered, removedSegmentFromGroupID, setRemovedSegmentFromGroupID }: GroupProps & { removedSegmentFromGroupID: string | undefined; setRemovedSegmentFromGroupID: (id: string | undefined) => void, hovered: boolean }) {
    const editingGroup = useManager('editingGroup');
    const editing = useEditor('editing');
    const segmentsOfGroup = useGroup(group, 'segments');
    const groupAttachments = useGroup(group, 'attachments');
    const name = useGroup(group, 'name');
    const manager = useManager('self');
    const selectedElements = useManager('selectedElements');
    const selectedAttachments = useState<Array<Attachment>>([]);
    const [attachmentIsOpen, setAttachmentIsOpen] = useState(false);
    const isOpen = useGroup(group, 'isOpenForEditing');
    const editingGroupElementsRef = useRef<HTMLDivElement | null>(null);
    const editingGroupElementsHeight = useChildElementsHeight({ ref: editingGroupElementsRef, target: segmentsOfGroup })
    const [approvedSegments, setApprovedSegments] = useState<ApprovedSegment[]>([])
    const [newSegmentFlag, setNewSegmentFlag] = useState<boolean>(false)
    const [isAllApproved, setIsAllApproved] = useState<boolean>(true);


    useEffect(() => {
        if (removedSegmentFromGroupID === undefined) return
        setApprovedSegments(prevApprovedSegments => prevApprovedSegments.filter(approvedSegment => approvedSegment.segment.id !== removedSegmentFromGroupID));
        setRemovedSegmentFromGroupID(undefined)
    }, [removedSegmentFromGroupID, setRemovedSegmentFromGroupID]);


    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        setNewSegmentFlag(true);
        timeoutId = setTimeout(() => {
            setNewSegmentFlag(false);
        }, 500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [approvedSegments.length]);


    const selected = useMemo(() => selectedElements.includes(group), [selectedElements, group]);


    const handleActivate = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (editingGroup || editing) return;

        let target: unknown = e.target;
        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (['checkbox', 'button'].includes(target.getAttribute('data-type') as any)) return;
            target = target.parentElement;
        }

        if (selected) manager.unselectElement(group);
        else manager.selectOneElement(group);
    }, [group, manager, editingGroup, selected, editing]);

    // const handleCheckbox = useCallback(() => {
    //     if (editingGroup || editing) return;
    //     if (selected) segments.unselectElement(group);
    //     else segments.selectElement(group);
    // }, [selected, segments, group, editing, editingGroup]);

    //!!!!
    // const description = useMemo(() => [group.description, ...tempApprovedSegments.map(item => item.segment.description)].filter(Boolean).join(', '), [group.description, tempApprovedSegments]) || 'Group description:';
    //const attachments = useMemo(() => [...new Set([...groupAttachments, ...tempApprovedSegments.flatMap(item => item.segment.attachments)])], [groupAttachments, tempApprovedSegments]);

    const description = useMemo(() => {
        const approvedDescriptions = approvedSegments.filter((item) => item.isApproved).map((item) => item.segment.description);
        const combinedDescriptions = [group.description, ...approvedDescriptions].filter(Boolean)
            .map((description, index) => { return index > 1 ? `, ${description}` : description; })
            .join('');
        return combinedDescriptions || 'Group description: ';
    }, [group.description, approvedSegments]);

    const attachments = useMemo(() => {
        const approvedAttachments = approvedSegments.filter(item => item.isApproved).flatMap(item => item.segment.attachments);
        return [...new Set([...groupAttachments, ...approvedAttachments])];
    }, [groupAttachments, approvedSegments]);


    // const handleClickAttachmentsHeaderCheckbox = useCallback(() => {
    //     if (attachments.length === selectedAttachments[0].length) selectedAttachments[1]([]);
    //     else selectedAttachments[1](attachments);
    // }, [attachments, selectedAttachments]);

    const handleClickSegment = useCallback(async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, segment: Segment) => {
        if (!e.ctrlKey && !e.metaKey) return;
        if (group.segments.includes(segment)) {
            manager.removeSegmentFromGroup(segment, group, 0);
        }
        else throw new Error('Group has this segment.');
    }, [group, manager]);

    // useEffect(() => {
    //     if (attachments.length) {
    //         group.isOpenForEditing = false;
    //         setAttachmentIsOpen(true);
    //     }
    //     else setAttachmentIsOpen(false);
    // }, [attachments, group]);


    const clickOpen = useCallback(() => {
        if (!isOpen) setAttachmentIsOpen(false);
        group.isOpenForEditing = !group.isOpenForEditing;
    }, [isOpen, group]);

    const clickOpenAttachments = useCallback(() => {
        if (!attachmentIsOpen) group.isOpenForEditing = false;
        setAttachmentIsOpen(prev => !prev);
    }, [attachmentIsOpen, group]);

    const groupAttachRef = useClickOutside(() => {
        if (editingGroup || editing) selectedAttachments[1]([])

    })
    const attachmentsHeight = useChildElementsHeight({ ref: groupAttachRef, target: attachments })

    function groupSegmentApproveCheck(id: string, approved: boolean) {
        const segment = segmentsOfGroup.find((segment) => segment.id === id);
        if (!segment) return;
        const tempApprovedSegment: ApprovedSegment = {
            segment: segment,
            isApproved: approved,
        }
        setApprovedSegments((prev) => [...prev, tempApprovedSegment])
    }

    const finishEdit = () => {
        if (segmentsOfGroup.length === 0) {
            manager.ungroup([group] as Array<GroupClass>);
            setIsAllApproved(true)
            manager.cancelEditingGroup()
        } else {
            const filteredSegments = segmentsOfGroup.filter(segment => segment.description !== '' || segment.attachments.length > 0);
            if (filteredSegments.length === approvedSegments.length) {
                setNotification(null)
                manager.doneEditingGroup(description.replace(/Group description: /, ''), attachments)
            }
            else {
                setIsAllApproved(false)
                setNotification(Notifications.SAVE_GROUP_ERROR)
            };
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useHotKey('Enter', useCallback(() => finishEdit(), [manager, description, attachments, segmentsOfGroup, approvedSegments]), useMemo(() => ({ condition: Boolean(editingGroup) }), [editingGroup]));

    return (
        <>
            <Draggable draggableId={group.id} index={index} isDragDisabled>
                {(providedGroup, snapshotGroup) => (
                    <div {...providedGroup.draggableProps} ref={providedGroup.innerRef}>
                        <div id={'element_' + group.id} className={classNames({ group: true, 'group_is-being-edited': true, 'group_is-being-dragged': snapshotGroup.isDragging, group_active: selected, 'group_hovered-and-close': hovered && !isOpen })} onClick={handleActivate}>
                            <div className="group__cell1">
                                <div className="group__drag" {...providedGroup.dragHandleProps} />
                                <div />
                                {/* <div className="group__checkbox">
                                    <Checkbox className={classNames({ 'group__checkbox-icon_active': selected, 'group__checkbox-icon_inactive': (editingGroup || editing) })} onClick={handleCheckbox} />
                                </div> */}
                            </div>
                            <div className="group__cell2">
                                <DropSvg className={classNames({ 'group__open-button': true, 'group__open-button_active': isOpen })} onClick={clickOpen} />
                                <div className="group__index">Group {name.toString().padStart(3, '0')}</div>
                            </div>
                            <div className="group__cell4">
                                {/* //disabled for GROUPS */}
                                <TextareaAutosize className="group__desc" placeholder="Note" value={description} style={{ color: newSegmentFlag ? '#0463E1' : '#000' }} />
                            </div>

                            <div className="group__cell3">
                                <div className={`group__cell3-attachments ${attachments.length ? '' : 'group__cell3-attachments_disabled'}`}

                                    onClick={() => {
                                        if (attachments.length)
                                            clickOpenAttachments()
                                    }}>
                                    {Boolean(attachments.length) ? <div className={classNames({ 'group__attach-drop': true, 'group__attach-drop_active': attachmentIsOpen })} ><DropSvg /></div> :
                                        <div className='segment__attach-drop' />}
                                    <div className="group__attach-button"
                                    // onClick={() => setElementForAttach(group)}
                                    >
                                        <AttachSvg className='group__attach-icon' />
                                    </div>
                                    Attachments<span className='group__attach-count' style={{ color: newSegmentFlag ? '#0463E1' : '#000' }}>&nbsp;({attachments.length})</span>
                                    {/* Attachmentssss */}
                                </div>
                            </div>
                            <div />
                            <div />
                            <div />
                            <div />
                            <div className='group_approve'>
                                <Button size="secondary" icon="success" color='dark' onClick={finishEdit}>DONE</Button>
                                <Button size="secondary" icon="cross" onClick={() => {
                                    setNotification(null)
                                    if (group.state === 'editing') {
                                        setIsAllApproved(true)
                                        manager.cancelEditingGroup()
                                    }
                                    if (group.state === 'creating') {
                                        manager.ungroup([group] as Array<GroupClass>);
                                        manager.unselectAllElements();
                                        manager.canselCreatingGroup();
                                        //segments.doneEditingGroup(description, attachments)
                                    }
                                }}>CANCEL</Button>
                            </div>
                            {/* <div data-type="button" className="group__cell5 group__cell5_disabled">
                                <DeleteSvg />
                            </div> */}
                        </div>
                        {
                            attachments &&
                            <div className={classNames({ group__attachments: true, group__attachments_open: attachmentIsOpen, group__attachments_selected: selected })}
                                ref={groupAttachRef}
                                style={{ maxHeight: attachmentIsOpen ? attachmentsHeight : 0 }}>
                                {attachments.map((attachment, index) => <AttachmentComponent element={group} attachment={attachment} selectedAttachments={selectedAttachments} key={`attachment_${group.id}_${attachment.filename}_${index}`} elementId='attachment_editing-group_' index={index} />)}
                            </div>
                        }
                    </div>
                )}
            </Draggable>
            <div className={classNames({ segments_of_group: true, segments_of_group_open: isOpen })}
                ref={editingGroupElementsRef}
                style={{ maxHeight: isOpen ? editingGroupElementsHeight : 0 }}>
                {/* // style={{ maxHeight: isOpen ? editingGroupElementsHeight + 52 * editingGroupAttachNumber : 0 }}> */}
                {segmentsOfGroup.map((segment, i) => {
                    const isSegmentApproved = approvedSegments.some(approvedItem => approvedItem.segment.id === segment.id);
                    return (<Draggable draggableId={segment.id} index={index + 1 + i} key={segment.id}>
                        {(providedGroup, snapshotSegmentGroup) => (
                            <div {...providedGroup.draggableProps} ref={providedGroup.innerRef} onClick={e => handleClickSegment(e, segment)}>
                                <GroupSegmentElement segment={segment} dragHandle={providedGroup.dragHandleProps} show={isOpen} group={group} isGroupEdited={true} onApproveCheck={groupSegmentApproveCheck} isApproved={isSegmentApproved} isAllApproved={isAllApproved} isDragging={snapshotSegmentGroup.isDragging} index={i} />
                            </div>
                        )}
                    </Draggable>)
                })}
            </div>
        </>
    );
}


export function Group({ group, index, realIndex }: GroupProps) {
    const editingGroup = useManager('editingGroup');
    const segmentsOfGroup = useGroup(group, 'segments');
    const description = useGroup(group, 'description');
    const attachments = useGroup(group, 'attachments');
    const name = useGroup(group, 'name');
    const editing = useEditor('editing');
    const manager = useManager('self');
    const selectedElemets = useManager('selectedElements');
    const [attachmentIsOpen, setAttachmentIsOpen] = useState(false);
    const selectedAttachments = useState<Array<Attachment>>([]);
    const [isOpen, setIsOpen] = useState(false);
    const groupElementsRef = useRef<HTMLDivElement | null>(null);
    const attachRef = useRef<HTMLDivElement | null>(null);

    const groupElementsHeight = useChildElementsHeight({ ref: groupElementsRef, target: segmentsOfGroup })
    const attachmentsHeight = useChildElementsHeight({ ref: attachRef, target: attachments })

    const selected = useMemo(() => selectedElemets.includes(group), [selectedElemets, group]);
    const tutorial = useStore($tutorial)

    const handleActivate = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (editingGroup || editing) return;

        let target: unknown = e.target;
        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (['checkbox', 'button'].includes(target.getAttribute('data-type') as any)) return;
            target = target.parentElement;
        }

        if (selected) manager.unselectElement(group);
        else manager.selectOneElement(group);
    }, [group, manager, selected, editing, editingGroup]);

    const handleCheckbox = useCallback(() => {
        if (editingGroup || editing) return;
        if (selected) manager.unselectElement(group);
        else manager.selectElement(group);
    }, [selected, manager, group, editing, editingGroup]);

    useEffect(() => {
        if (attachments.length) {
            setIsOpen(false);
            setAttachmentIsOpen(true);
        }
        else setAttachmentIsOpen(false);
    }, [attachments]);

    const clickOpen = useCallback(() => {
        if (!isOpen) setAttachmentIsOpen(false);
        setIsOpen(prev => !prev);
    }, [isOpen]);

    const clickOpenAttachments = useCallback(() => {
        if (!attachmentIsOpen) setIsOpen(false);
        setAttachmentIsOpen(prev => !prev);
    }, [attachmentIsOpen]);


    useEffect(() => {
        if (tutorial.step === 0) return manager.unselectAllElements();
        if ((realIndex === 0 && tutorial.step === 1) ||
            (realIndex === 1 && tutorial.step === 2) ||
            (realIndex === 2 && tutorial.step === 3)) {
            manager.unselectAllElements();
            manager.selectElement(group);
        } else if (tutorial.step === 4 || tutorial.step === 0) {
            manager.unselectAllElements();
        }
    }, [group, index, manager, realIndex, tutorial.step])

    return (
        <Draggable draggableId={group.id} index={index}>
            {(providedGroup, snapshotGroup) => (
                <div {...providedGroup.draggableProps} ref={providedGroup.innerRef}>
                    <div
                        id={'element_' + group.id}
                        className={classNames({ group: true, 'group_is-being-dragged': snapshotGroup.isDragging, group_active: selected })}
                        onClick={handleActivate}
                    >
                        <div className="group__cell1">
                            <div className="group__drag" {...providedGroup.dragHandleProps} />
                            <div className="group__checkbox">
                                <Checkbox className={classNames({ 'group__checkbox-icon_active': selected, 'group__checkbox-icon_inactive': (editingGroup || editing) })} onClick={handleCheckbox} />
                            </div>
                        </div>
                        <div className="group__cell2">
                            <DropSvg className={classNames({ 'group__open-button': true, 'group__open-button_active': isOpen })} onClick={clickOpen} />
                            <div className="group__index"> Group {name.toString().padStart(3, '0')}</div>
                        </div>
                        <div className="group__cell4">
                            <TextareaAutosize className="group__desc" placeholder="Note" value={description} onChange={e => group.description = e.currentTarget.value} />
                        </div>
                        <div className="group__cell3">
                            <div
                                className={`group__cell3-attachments ${attachments.length ? '' : 'group__cell3-attachments_disabled'}`}
                                onClick={() => {
                                    if (attachments.length)
                                        clickOpenAttachments();
                                }}
                            >
                                {
                                    Boolean(attachments.length)
                                        ?
                                        <div className={classNames({ 'group__attach-drop': true, 'group__attach-drop_active': attachmentIsOpen })}><DropSvg /></div>
                                        :
                                        <div className='segment__attach-drop' />
                                }
                                <div className="group__attach-button">
                                    <AttachSvg className='group__attach-icon' />
                                </div>
                                Attachments ({attachments.length})
                            </div>
                        </div>
                        <div className='segment__cell6'>
                            <button className='segment__attach-add_button' onClick={() => { setNotification(Notifications.MORE_ATTACHMENTS_INFO); setElementForAttach(group) }} >
                                <AddAttachSvg />
                                Add
                            </button>
                        </div>
                        <div data-type="button" className="group__cell5" onClick={() => manager.removeGroup(group)}>
                            <DeleteSvg />
                        </div>
                    </div>
                    {/* //CHECK */}
                    <div className={classNames({ segments_of_group: true, segments_of_group_open: isOpen })}
                        ref={groupElementsRef}
                        style={{ maxHeight: isOpen ? groupElementsHeight : 0 }}>
                        {segmentsOfGroup.map((segment, index) => <GroupSegmentElement segment={segment} key={segment.id} group={group} index={index} isGroupEdited={false} />)}
                    </div>
                    {
                        attachments &&
                        <div className={classNames({ group__attachments: true, group__attachments_open: attachmentIsOpen, group__attachments_selected: selected })}
                            ref={attachRef} style={{ maxHeight: attachmentIsOpen ? attachmentsHeight : 0 }}>
                            {attachments.map((attachment, index) => <AttachmentComponent element={group} attachment={attachment} selectedAttachments={selectedAttachments} key={`attachment_${group.id}_${attachment.filename}_${index}`} elementId='attachment_group_' index={index} />)}
                        </div>
                    }
                </div>
            )}
        </Draggable>
    );
}

type SegmentElementProps = {
    segment: Segment;
    show?: boolean;
    dragHandle?: any;
    group: GroupClass;
    isGroupEdited?: boolean;
    onApproveCheck?: (id: string, approved: boolean) => void;
    isApproved?: boolean;
    isAllApproved?: boolean;
    isDragging?: boolean;
    index: number;
};

function GroupSegmentElement({ segment, dragHandle = {}, show = true, group, isGroupEdited = false, onApproveCheck, isApproved = false, isAllApproved = true, isDragging = false, index }: SegmentElementProps) {
    const manager = useManager('self');
    const editingGroup = useManager('editingGroup');
    const editing = useEditor('editing');
    const selectedElemets = useManager('selectedElements');
    const description = useSegment(segment, 'description');
    const attachments = useSegment(segment, 'attachments');
    const [attachmentIsOpen, setAttachmentIsOpen] = useState(false);

    const groupSegmentAttachRef = useRef<HTMLDivElement | null>(null);
    const groupSegmentAttachHeight = useChildElementsHeight({ ref: groupSegmentAttachRef, target: attachments })
    const [selectedAttachments, setSelectedAttachments] = useState<Array<Attachment>>([]);

    const [attachmentsState, setAttachmentsState] = useState<Attachment[]>(attachments);
    const [descriptionState, setDescriptionState] = useState<string>(description);
    const selected = useMemo(() => selectedElemets.includes(segment), [selectedElemets, segment]);


    useEffect(() => {
        if (isApproved) {
            setAttachmentsState([])
            setDescriptionState('')
        }
    }, [isApproved])
    // const handleCheckbox = useCallback(() => {
    //     if (editingGroup || editing) return;
    //     if (selected) segments.unselectElement(segment);
    //     else segments.selectElement(segment);
    // }, [selected, segments, segment, editing, editingGroup]);

    const handleSelect = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (editingGroup) {
            const group = manager.editingGroup;
            if (!group) throw new Error('Group is not being edited.');
            if (!e.ctrlKey && !e.metaKey) return;
            if (group.segments.includes(segment)) throw new Error('Group has this segment.');
            else manager.addSegmentToGroup(segment, group, group.segments.length);
            return;
        }
        if (editing) return;
        let target: unknown = e.target;

        while (true) {
            if (!(target instanceof Element)) break;
            if (target === e.currentTarget) break;
            if (['checkbox', 'button'].includes(target.getAttribute('data-type') as any)) return;
            target = target.parentElement;
        }

        manager.selectOneElement(segment);
    }, [segment, manager, editingGroup, editing]);


    useEffect(() => {
        if (attachments.length) setAttachmentIsOpen(true);
        else setAttachmentIsOpen(false);
    }, [attachments]);


    return (
        <div>
            <div className={classNames({ "group-segment": true, "group-segment_active": selected, "group-segment_is-being-dragged": isDragging })} onClick={handleSelect}>
                <div className="group-segment__cell1">
                    <div className="group-segment__drag" {...dragHandle} />
                </div>
                <div className="group-segment____color-cell">
                    {(!isAllApproved && (attachmentsState.length !== 0 || descriptionState !== '')) &&
                        <div className='group-segment____alarm-icon' id={`segment_alarm-icon_${index}`}
                            onMouseEnter={() => setHint({ id: `segment_alarm-icon_`, index: index.toLocaleString() })}
                            onMouseLeave={() => setHint(null)} />
                    }

                    <div
                        className="group-segment__color"
                        id={`color_circle-group_segment_${index}`}
                        onMouseEnter={() => setHint({ id: 'color_circle-group_segment_', index: index.toLocaleString() })}
                        onMouseLeave={() => setHint(null)}
                        style={{ backgroundColor: `rgb(${segment.color[0]}, ${segment.color[1]}, ${segment.color[2]})` }}
                        onClick={() => manager.changeToRandomColor(segment)}
                    />
                    <div className="group-segment__index">{segment.name.toString().padStart(3, '0')}</div>
                </div>
                <div className="group__cell4">
                    <TextareaAutosize className="group__desc" placeholder="Note" value={descriptionState} disabled
                        style={{ color: isAllApproved ? '#000' : '#F62F53' }} />
                </div>

                <div
                    className={classNames({ 'segment__cell3': true, 'segment__cell3_disabled': !Boolean(attachmentsState.length) })}
                >
                    <div className={`segment__cell3-attachments ${(attachmentsState.length > 0) ? '' : 'segment__cell3-attachments_disabled'}`}
                        onClick={() => { if (attachmentsState.length) setAttachmentIsOpen(prev => !prev) }}>

                        {Boolean(attachmentsState.length) ?
                            <div className={classNames({ "segment__attach-drop": true, "segment__attach-drop_active": attachmentIsOpen })}><DropAttachSvg /></div> :
                            <div className='segment__attach-drop' />}
                        <div className="segment__attach-button">
                            <AttachSvg className='segment__attach-icon' />
                        </div>
                        Attachments <span className='segment__attach-count' style={{ color: !isAllApproved && attachmentsState.length !== 0 ? '#F62F53' : '#000', }}>&nbsp;({attachmentsState.length})</span>
                    </div>
                </div>
                {((attachmentsState.length || descriptionState !== '') && onApproveCheck) &&
                    <div className='group__cell-approve_bttns'>
                        <button className='group__cell-approve_button' onClick={() => onApproveCheck(segment.id, true)}
                            id={`segment_include-bttn_${index}`}
                            onMouseEnter={() => setHint({ id: 'segment_include-bttn_', index: index.toLocaleString() })}
                            onMouseLeave={() => setHint(null)}>
                            Include
                        </button>
                        <button className='group__cell-approve_button' onClick={() => onApproveCheck(segment.id, false)}>
                            Discard
                        </button>

                    </div>
                }
                {/* <div data-type="button" className="group-segment__cell5" onClick={() => segments.removeSegmentFromGroup(segment, group, -2)}>
                <DeleteSvg />
            </div> */}
            </div>
            {attachmentsState.length > 0 &&
                <div className={classNames({ segment__attachments: true, segment__attachments_open: attachmentIsOpen, segment__attachments_selected: selected })}
                    ref={groupSegmentAttachRef}
                    style={{ maxHeight: attachmentIsOpen ? groupSegmentAttachHeight : 0 }}>
                    {attachments.map((attachment, index) => <AttachmentComponent element={segment} attachment={attachment} selectedAttachments={[selectedAttachments, setSelectedAttachments]} key={`attachment_${segment.id}_${attachment.filename}_${index}`} elementId='attachment_group-segment-element_' index={index} />)}
                </div>}
        </div>
    );
}
