import { useEffect, useState } from "react";
import { useStore } from "effector-react";
import { $prevSketchState, $tutorial, next, setPrevSketchState, setTutorial, skip, tutorialItems } from "entities/tutorial";
import Button from "Components/Button";
import classNames from "classnames";
import { setTutorialHint } from "entities/hint";
import { useManager } from "entities/sketch/Manager";
import './Tutorial.scss'


function Tutorial() {
    const tutorial = useStore($tutorial);
    const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);
    const [stop, setStop] = useState<boolean>(false);
    const [isTutorialcompleted, setIsTutorialCompleted] = useState<boolean>(false);
    const [timelinePercent, setTimelinePercent] = useState<number>(0);
    const animationDuration = 16000;
    const [currentGap, setCurrentGap] = useState<number>(20);
    const [prevStep, setPrevStep] = useState<number>(1);
    const [showCheckBox, setShowCheckBox] = useState<boolean>(true);
    const prevSketchState = useStore($prevSketchState);
    const segments = useManager('self');

    useEffect(() => {
        const rawCompleted = localStorage.getItem('show_tutorial');
        if (!rawCompleted) setShowCheckBox(true)
        else setShowCheckBox(JSON.parse(rawCompleted));
    }, []);

    useEffect(() => {
        if (tutorial.step === 0) return
        let startTime = performance.now() - timelinePercent / 100 * animationDuration;
        let animationFrameId = 0;
        const updateWidth = (timestamp: number) => {
            if (stop) return;
            const progress = timestamp - startTime;
            const percentage = Math.min(progress / animationDuration, 1) * 100;
            setTimelinePercent(percentage);
            if (!stop && progress < animationDuration) animationFrameId = requestAnimationFrame(updateWidth);
        };
        if (!stop) animationFrameId = requestAnimationFrame(updateWidth);
        return () => cancelAnimationFrame(animationFrameId);
    }, [animationDuration, stop, timelinePercent, tutorial.step]);


    useEffect(() => {
        const gap = 100 / tutorialItems.length;
        if (timelinePercent >= 100) {
            setIsTutorialCompleted(true);
            setStop(true);
        }
        else if (timelinePercent >= currentGap && prevStep === tutorial.step) {
            setCurrentGap((prev) => prev + gap);
            setPrevStep((prev) => prev + 1);
            next();
        }
    }, [currentGap, prevStep, timelinePercent, tutorial.step]);

    const setZIndex = (elementIds: string[]) => {
        elementIds.forEach(id => {
            const element = document.getElementById(id);
            if (element) {
                element.style.zIndex = '7';
                element.style.pointerEvents = 'none';
            }
        });
    };
    const resetZIndex = (prevElementsIds: string[]) => {
        prevElementsIds.forEach(id => {
            const element = document.getElementById(id);
            if (element) {
                if (id === 'help') element.style.zIndex = '5';
                else element.style.zIndex = '0';
                element.style.pointerEvents = 'auto';
            }
        });
    };

    useEffect(() => {
        const prevElementsIds = tutorial.prevElementsIds;
        const elementIds = tutorial.elementsIds;
        if (prevElementsIds) resetZIndex(prevElementsIds);
        if (elementIds) setZIndex(elementIds);
    }, [tutorial.elementsIds, tutorial.prevElementsIds]);

    function getTutorialText(tutorialText: string[]) {
        if (tutorial.step === -1) return (<p>hover and wait for extra tooltips</p>);
        return (<>{tutorialText.map((item, index) => (<p key={index}>{item}</p>))}</>);
    }

    function tutorialRestart() {
        setTutorial();
        setIsTutorialCompleted(false);
        setTimelinePercent(0);
        setStop(false);
        setCurrentGap(20);
        setPrevStep(1);
    }

    function finishTutorial() {
        localStorage.setItem('show_tutorial', JSON.stringify(!dontShowAgain));
        const prevElementsIds = tutorial.prevElementsIds;
        const elementIds = tutorial.elementsIds;
        if (prevElementsIds) resetZIndex(prevElementsIds);
        if (elementIds) resetZIndex(elementIds);
        skip();
        setTutorialHint(null)
        if (prevSketchState.showUndefined) segments.undefinedMode = true
/*         if (prevSketchState.hideMasks) segments.hideSegments = true */
        setPrevSketchState({ showUndefined: false, hideMasks: false })
    }

    return (
        <>
            {tutorial.step !== 0 &&
                <>
                    <div className="sketch-tutorial-cover" />
                    <div className="black_sketch-tutorial__tooltip-wrapper">
                        <div className={classNames({ "black_sketch-tutorial__tooltip": true, 'black_sketch-tutorial__tooltip_dont-show': !showCheckBox })}>
                            <div className="tutorial__tooltip_timeline" style={{ width: `${timelinePercent}%` }} />
                            <div className="tutorial__tooltip-play_controller" onClick={() => {
                                if (isTutorialcompleted) {
                                    tutorialRestart()
                                }
                                else setStop((prev) => !prev)
                            }}>
                                {isTutorialcompleted ? <div className="play_controller-replay" /> :
                                    <div className={`play_controller-${stop ? 'play' : 'stop'}`} />}
                            </div>
                            <div className="tutorial__tooltip-text">
                                {getTutorialText(tutorial.tooltipText)}
                            </div>
                            <div className={classNames({ "tutorial__tooltip-finish_bttn-wr": true, 'tutorial__tooltip-finish_bttn-wr_dont-show': !showCheckBox })}>
                                <Button className="tutorial__tooltip-finish_bttn" color="dark" onClick={finishTutorial}>finish tutorial</Button>
                            </div>
                            {showCheckBox &&
                                <div className="tutorial__tooltip-never_show">
                                    <div className="tutorial__tooltip-never_show_checkbox-wrapper" onClick={() => setDontShowAgain((prev) => !prev)}>
                                        {dontShowAgain && <div className="tutorial__tooltip-never_show_checkbox-icon" />}
                                    </div>
                                    <p>Never show again</p>
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default Tutorial;
