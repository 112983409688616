import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { $file } from 'entities/sketch';
import { useStore } from 'effector-react';
import * as Listeners from '../Listeners';
import { ReactComponent as PlusSvg } from './icons/plus.svg';
import { ReactComponent as MinusSvg } from './icons/minus.svg';
import { useManager } from 'entities/sketch/Manager';
import useStatusBar from './hooks/useStatusBar';
import { setHint } from 'entities/hint';
import useCursor from './hooks/useCursos';
import { $modal, ModalType } from 'entities/modal';
import { $elementForAttach } from 'entities/library';
import Switcher from 'Components/Switcher';
import { $tutorial } from 'entities/tutorial';
import './Drawing.scss';


const minZoom = 0.3;
const maxZoom = 3;

function myDrag(map: React.MutableRefObject<HTMLDivElement | null>) {
    let left = 0;
    let top = 0;
    let zoom = 1;

    const zoomFn = (event: { deltaY: number; clientX: number, clientY: number }) => {
        if (!map.current) return;

        const { clientX, clientY } = event;
        const { x, y } = map.current?.getBoundingClientRect();

        const scale =
            event.deltaY > 0
                ? Math.max(zoom / 1.1, minZoom)
                : Math.min(zoom * 1.1, maxZoom);

        const multiplier = event.deltaY > 0
            ? zoom / scale
            : scale / zoom;
        zoom = scale;

        map.current.style.transform = `scale(${zoom})`;

        const prevTop = Number(map.current.style.top.slice(0, -2));
        const prevLeft = Number(map.current.style.left.slice(0, -2));

        const { top, left } = {
            top:
                event.deltaY > 0
                    ? prevTop + ((clientY - y) * (multiplier - 1)) / multiplier
                    : prevTop - (clientY - y) * (multiplier - 1),
            left:
                event.deltaY > 0
                    ? prevLeft + ((clientX - x) * (multiplier - 1)) / multiplier
                    : prevLeft - (clientX - x) * (multiplier - 1),
        };

        map.current.style.left = left + 'px';
        map.current.style.top = top + 'px';
    };

    return {
        drag(event: React.MouseEvent<HTMLDivElement>) {
            if (!map.current) return;
            map.current.style.left = (Number(map.current.style.left.slice(0, -2)) + (event.clientX - left)) + 'px';
            map.current.style.top = (Number(map.current.style.top.slice(0, -2)) + (event.clientY - top)) + 'px';
            left = event.clientX;
            top = event.clientY;
        },
        positionUpd(event: React.MouseEvent<HTMLDivElement> | MouseEvent) {
            left = event.clientX;
            top = event.clientY;
        },
        zoom: zoomFn,
        zoomFromButton(deltaY: 1 | -1) {
            if (!map.current) return;
            const { width, height } = map.current?.getBoundingClientRect();
            zoomFn({ deltaY, clientX: width / zoom / 1.5, clientY: height / zoom / 2 });
        },
        fit() {
            left = 0;
            top = 0;
            zoom = 1;
            if (!map.current) return;
            map.current.style.left = 0 + 'px';
            map.current.style.top = 0 + 'px';
            map.current.style.transform = `scale(1)`;
        },
        getZoom() {
            return zoom;
        },
    };
}

type DrawingProps = {
    listener: Listeners.Listener;
    setListener: React.Dispatch<React.SetStateAction<Listeners.Listener>>;
};

export default function Drawing({ listener, setListener }: DrawingProps) {
    const imageRef = useRef<HTMLImageElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const zoomRef = useRef<HTMLDivElement | null>(null);
    const [size, setSize] = useState<{ width: 'auto' | number, height: number }>({ width: 'auto', height: 300 });
    const [dragIsActive, setDragIsActive] = useState(false);
    const [zoomIsActive, setZoomIsActive] = useState(true);
    const status = useStatusBar(listener);
    const modal = useStore($modal);
    const elementForAttach = useStore($elementForAttach);
    const drawingFooterRef = useRef<HTMLDivElement | null>(null);
    const [drawingFooterHeight, setDrawingFooterHeight] = useState<number>(0)
    const tutorial = useStore($tutorial);
    const manager = useManager('self');
    const file = useStore($file);

    useEffect(() => {
        if (drawingFooterRef.current) {
            const height = drawingFooterRef.current.offsetHeight;
            setDrawingFooterHeight(height);
        }
    }, []);

    const { drag, positionUpd, zoom, zoomFromButton, fit, getZoom } = useMemo(() => myDrag(zoomRef), [zoomRef]);

    useEffect(() => listener.setGetZoom(getZoom), [listener, getZoom]);

    useEffect(() => void (manager.segmentEditor.getZoom = getZoom), [manager, getZoom]);

    useCursor(listener);

    const dragStart = useCallback((event: React.MouseEvent<HTMLDivElement> | MouseEvent) => {
        positionUpd(event);
        setDragIsActive(true);
    }, [positionUpd]);

    const dragEnd = useCallback(() => setDragIsActive(false), []);

    useEffect(() => {
        const canvas = document.getElementById('drawing__container');
        if (!canvas) return;
        let prev: Listeners.Listener | undefined;

        function start(e: MouseEvent) {
            if (e.button !== 1) return;
            setListener(value => {
                prev = value;
                return new Listeners.Zoom(manager);
            });
            dragStart(e);
        }

        function end() {
            if (!prev) return;
            setListener(prev);
            prev = undefined;
            dragEnd();
        }

        canvas.addEventListener('mousedown', start);
        canvas.addEventListener('mouseup', end);
        canvas.addEventListener('mouseleave', end);
        return () => {
            canvas.removeEventListener('mousedown', start);
            canvas.removeEventListener('mouseup', end);
            canvas.addEventListener('mouseleave', end);
        };
    }, [dragEnd, dragStart, manager, setListener]);

    useEffect(() => {
        const canvas = document.getElementById('canvas');
        if (!canvas) return;
        let prev: Listeners.Listener | undefined;

        function start(e: KeyboardEvent) {
            if (e.code === 'Space' && document.activeElement instanceof HTMLBodyElement) {
                setListener(value => {
                    if (value instanceof Listeners.Zoom) return value;
                    prev = value;
                    return new Listeners.Zoom(manager);
                });
            }
        }

        function end(e: KeyboardEvent) {
            if (!prev || e.code !== 'Space') return;
            setListener(prev);
            prev = undefined;
            dragEnd();
        }

        document.addEventListener('keydown', start);
        document.addEventListener('keyup', end);
        return () => {
            document.removeEventListener('keydown', start);
            document.removeEventListener('keyup', end);
        };
    }, [dragEnd, manager, setListener]);

    useEffect(() => {
        if (modal.type === ModalType.NONE && !elementForAttach) {
            function fn(e: KeyboardEvent) {
                if (e.code !== 'Escape') return;

                manager.unselectAllElements();
            }

            document.addEventListener('keydown', fn);
            return () => document.removeEventListener('keydown', fn);
        }
    }, [modal, manager, elementForAttach]);

    const handleMove = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (!dragIsActive) return;
        event.persist();
        requestAnimationFrame(() => drag(event));
    }, [dragIsActive, drag]);

    const treeWheel = useCallback((event: React.WheelEvent<HTMLDivElement>) => {
        if (tutorial.step !== 0) return
        event.persist();
        event.stopPropagation();
        requestAnimationFrame(() => zoom(event));
    }, [tutorial.step, zoom]);

    useEffect(() => setZoomIsActive(listener instanceof Listeners.Zoom), [listener]);

    const handleKeydown = useCallback((e: KeyboardEvent) => {
        if (listener instanceof Listeners.Select && e.key === 'Tab') {
            e.preventDefault();
            listener.handleTab();
        }
    }, [listener]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => document.removeEventListener('keydown', handleKeydown);
    }, [handleKeydown]);

    useEffect(() => {
        if (!imageRef.current) return;
        if (!containerRef.current) return;
        function update() {
            if (!imageRef.current) return;
            if (!containerRef.current) return;
            const width = containerRef.current.clientWidth;
            const { naturalWidth, naturalHeight } = imageRef.current;
            const scale = width / naturalWidth;
            const height = naturalHeight * scale;
            const aspectRatio = naturalWidth / naturalHeight;
            if (aspectRatio > 1) setSize(prev => {
                const height = width / aspectRatio;
                if (prev.width === width && prev.height === height) return prev;
                else return { width, height };
            });
            if (aspectRatio < 1) setSize(prev => {
                const width = height * aspectRatio;
                if (prev.width === width && prev.height === height) return prev;
                else return { width, height };
            });
            if (aspectRatio === 1) setSize(prev => {
                if (prev.width === width && prev.height === height) return prev;
                else return { width, height };
            });
            manager.scale = scale;
        }
        update();
        const resizeObserver = new ResizeObserver(update);
        imageRef.current.onload = update;

        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect();
    }, [manager]);

    useEffect(() => {
        const canvas = document.getElementById('canvas');
        if (!(canvas instanceof HTMLCanvasElement)) throw new Error('canvas not canvas =)');
        if (!manager) throw new Error('Segments not exist');
        manager.connectCanvas(canvas);
    }, [manager]);

    useEffect(() => {
        if (tutorial.step !== 0) {
            const tutorial_canvas = document.getElementById('tutorial_canvas')
            if (!(tutorial_canvas instanceof HTMLCanvasElement)) throw new Error('tutorial_canvas not canvas =)');
            manager.connectTutorialCanvas(tutorial_canvas, tutorial.step);
        }
    }, [manager, tutorial.step])

    //CHECK
    useEffect(() => {
        if (tutorial.step !== 0) {
            fit();
            manager.updateTutorialDraw(tutorial.step);
        }
    }, [fit, manager, tutorial.step])

    const imageSrc = useMemo(() => file ? URL.createObjectURL(file) : '', [file]);

    //CHECK
    const handleSelectNothing = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (zoomIsActive) return;
        if (event.target === zoomRef.current) manager.unselectAllElements();
    }, [zoomIsActive, manager]);

    return (
        <div className="drawing">
            <div
                ref={containerRef}
                onWheel={treeWheel}
                onMouseDown={zoomIsActive ? dragStart : listener instanceof Listeners.BoxListener ? listener.boxStart : undefined}
                onMouseUp={zoomIsActive ? dragEnd : listener instanceof Listeners.BoxListener ? listener.onMouseUp : undefined}
                onMouseLeave={zoomIsActive ? dragEnd : undefined}
                onMouseMove={zoomIsActive ? handleMove : listener instanceof Listeners.BoxListener ? listener.boxMove : undefined}
                onClick={handleSelectNothing}
                className="drawing__container"
                id="drawing__container"
            >
                <div ref={zoomRef} className="drawing__zoom">
                    <img draggable="false" ref={imageRef} className="drawing__image" alt="source file" src={imageSrc} style={{ ...size }} id="file" />
                    <div
                        id="canvas-container"
                        className={classNames({
                            "drawing__canvas-container": true,
                            "cursor-box": listener instanceof Listeners.BoxListener,
                            "cursor-add": listener instanceof Listeners.PositivePoint,
                            "cursor-remove": listener instanceof Listeners.NegativePoint,
                            "cursor-brush-tool": listener instanceof Listeners.BrushToolListener,
                        })}
                        style={{ ...size }}
                    >
                        <div className="drawing__label" id="label">Label</div>
                        <canvas
                            id="canvas"
                            className="drawing__canvas"
                            onMouseMove={e => listener.onMouseMove(e)}
                            onMouseDown={e => listener.onMouseDown(e)}
                            onMouseMoveCapture={e => listener.onMouseMoveCapture(e)}
                            onMouseLeave={(e => listener.onMouseLeave(e))}
                            onMouseUp={e => listener.onMouseUp(e)}
                            onClick={e => listener.onClick(e)}
                            onDoubleClick={e => listener.onDoubleClick(e)}
                            onContextMenu={e => listener.onContextMenu(e)}
                            {...size}
                        />
                    </div>
                </div>
                <div className="drawing__zoom-tutorial" style={{ display: tutorial.step === 0 ? 'none' : 'block' }}>
                    <div className={classNames({ "drawing__canvas-container-tutorial": true, })}
                        style={{ ...size }}
                    >
                        <canvas
                            id="tutorial_canvas"
                            className="drawing__canvas"
                            onMouseMove={e => listener.onMouseMove(e)}
                            onMouseDown={e => listener.onMouseDown(e)}
                            onMouseMoveCapture={e => listener.onMouseMoveCapture(e)}
                            onMouseLeave={(e => listener.onMouseLeave(e))}
                            onMouseUp={e => listener.onMouseUp(e)}
                            onClick={e => listener.onClick(e)}
                            onDoubleClick={e => listener.onDoubleClick(e)}
                            onContextMenu={e => listener.onContextMenu(e)}
                            {...size}
                        />
                    </div>
                </div>
                {tutorial && tutorial.step !== 0 && <div className='drawing__footer-bar_cover' style={{ height: drawingFooterHeight }} />}
                <div className="drawing__footer-bar" ref={drawingFooterRef}>
                    <div className='drawing__footer-bar-left_parth'>
                        <div className={classNames({ 'drawing__label-switcher': true/* , 'drawing__label-switcher_disabled': hideSegments || editing */ })}
                            id='undefined-switcher'
                            onMouseEnter={() => setHint({ id: 'undefined-switcher' })}
                            onMouseLeave={() => setHint(null)}
                        >
                            <div className="drawing__switcher-text">SHOW undefined</div>
{/*     <Switcher value={undefinedMode} onClick={() => {
        segments.unselectAllElements();
        segments.undefinedMode = !undefinedMode
    }
    } /> */}
                            <Switcher value={/* undefinedMode */false} onClick={() => 1/* manager.undefinedMode = !undefinedMode */} />
                        </div >
        <div className={classNames({ "drawing__original-switcher": true/* , "drawing__original-switcher_disabled": undefinedMode */ })}
            id='original_switcher'
            onMouseEnter={() => setHint({ id: 'original_switcher' })}
            onMouseLeave={() => setHint(null)}
/*             onClick={() => segments.hideSegments = !hideSegments} */
        >
            <div className="drawing__switcher-text">HIDE MASKS</div>
            <Switcher value={/* hideSegments */false} onClick={() => undefined} />
        </div>

                    </div >
        <div className="drawing__zoom-buttons" id='drawing__zoom-buttons'>
            <div className="drawing__zoom-minus"
                onClick={() => zoomFromButton(1)}
                id='zoom_in'
                onMouseEnter={() => setHint({ id: 'zoom_in' })}
                onMouseLeave={() => setHint(null)}
            >
                <MinusSvg />
            </div>
            <div className="drawing__zoom-plus"
                onClick={() => zoomFromButton(-1)}
                onMouseEnter={() => setHint({ id: 'zoom_out' })}
                onMouseLeave={() => setHint(null)}
                id='zoom_out'>
                <PlusSvg />
            </div>
            <div className="drawing__zoom-fit" onClick={() => fit()}
                id='zoom_fit'
                onMouseEnter={() => setHint({ id: 'zoom_fit' })}
                onMouseLeave={() => setHint(null)}>
                FIT
            </div>
        </div>
                </div >
            </div >
        <div className="drawing__footer">{status}</div>
        </div >
    );
}
