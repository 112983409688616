import * as constants from './constants';
import * as shaders from './shaders';
import { Box } from '../Manager';


type Color = [number, number, number, number];

interface Sprite {
    mask: Uint8Array;
    texture: WebGLTexture | null;
    renderColor: Color;
    selectOutlineWidth: number;
    hoverOutlineWidth: number;
}

type ProgramData = {
    program: WebGLProgram;
    vao: WebGLVertexArrayObject;
};

export default class Engine {
    private width = 0;
    private height = 0;
    private readonly sprites: Array<Sprite> = [];
    private gl: WebGL2RenderingContext | null = null;
    private programData: ProgramData | null = null;
    private outlineProgramData: ProgramData | null = null;
    private rectangleProgramData: ProgramData | null = null;
    frame: Box | null = null;

    constructor(sprites: Array<Sprite>) {
        this.sprites = sprites;
    }

    public async init(gl: WebGL2RenderingContext, width: number, height: number) {
        this.width = width;
        this.height = height;
        this.gl = gl;
        this.gl.enable(this.gl.BLEND);
        this.gl.blendFunc(this.gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        this.createProgram();
        this.createOutlineProgram();
        this.createRectangleProgram();
        await this.specifieTextures();
    }

    private async specifieTextures() {
        if (!this.gl) throw new Error('gl is null.');

        this.sprites.forEach(sprite => {
            if (!this.gl) throw new Error('gl is null.');

            sprite.texture = this.gl.createTexture();

            this.gl.bindTexture(this.gl.TEXTURE_2D, sprite.texture);
            const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
            this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, side, side, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, sprite.mask);
            this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
            this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
        });
    }

    private createProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.vertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.fragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('Program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');
        this.gl.bindVertexArray(vao);

        const positionBuffer = this.gl.createBuffer();
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, constants.position, this.gl.STATIC_DRAW);
        const positionAttribLocation = this.gl.getAttribLocation(program, 'aPosition');
        this.gl.enableVertexAttribArray(positionAttribLocation);
        this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

        const texCoordBuffer = this.gl.createBuffer();
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, texCoordBuffer);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, constants.texCoords, this.gl.STATIC_DRAW);
        const aTexCoordLocation = this.gl.getAttribLocation(program, 'aTexCoord');
        this.gl.enableVertexAttribArray(aTexCoordLocation);
        this.gl.vertexAttribPointer(aTexCoordLocation, 2, this.gl.FLOAT, false, 0, 0);

        this.gl.bindVertexArray(null);

        this.programData = { program, vao };

        this.gl.useProgram(program);

        const uCanvasSize = this.gl.getUniformLocation(program, 'uCanvasSize');
        this.gl.uniform2i(uCanvasSize, this.width, this.height);

        const uTexSize = this.gl.getUniformLocation(program, 'uTexSize');
        const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
        this.gl.uniform2f(uTexSize, side, side);
    }

    private createOutlineProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.vertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.outlineFragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('outline program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');
        this.gl.bindVertexArray(vao);

        const positionBuffer = this.gl.createBuffer();
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, constants.position, this.gl.STATIC_DRAW);
        const positionAttribLocation = this.gl.getAttribLocation(program, 'aPosition');
        this.gl.enableVertexAttribArray(positionAttribLocation);
        this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

        const texCoordBuffer = this.gl.createBuffer();
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, texCoordBuffer);
        this.gl.bufferData(this.gl.ARRAY_BUFFER, constants.texCoords, this.gl.STATIC_DRAW);
        const aTexCoordLocation = this.gl.getAttribLocation(program, 'aTexCoord');
        this.gl.enableVertexAttribArray(aTexCoordLocation);
        this.gl.vertexAttribPointer(aTexCoordLocation, 2, this.gl.FLOAT, false, 0, 0);

        this.gl.bindVertexArray(null);

        this.outlineProgramData = { program, vao };

        this.gl.useProgram(program);

        const uCanvasSize = this.gl.getUniformLocation(program, 'uCanvasSize');
        this.gl.uniform2i(uCanvasSize, this.width, this.height);

        const uTexSize = this.gl.getUniformLocation(program, 'uTexSize');
        const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
        this.gl.uniform2f(uTexSize, side, side);
    }

    private createRectangleProgram() {
        if (!this.gl) throw new Error('gl is null.');

        const vertexShader = this.gl.createShader(this.gl.VERTEX_SHADER);
        if (!vertexShader) throw new Error('error');
        this.gl.shaderSource(vertexShader, shaders.rectangleVertexShaderSource);
        this.gl.compileShader(vertexShader);

        const fragmentShader = this.gl.createShader(this.gl.FRAGMENT_SHADER);
        if (!fragmentShader) throw new Error('error');
        this.gl.shaderSource(fragmentShader, shaders.rectangleFragmentShaderSource);
        this.gl.compileShader(fragmentShader);

        const program = this.gl.createProgram();
        if (!program) throw new Error('outline program is null');
        this.gl.attachShader(program, vertexShader);
        this.gl.attachShader(program, fragmentShader);
        this.gl.linkProgram(program);

        const vao = this.gl.createVertexArray();
        if (!vao) throw new Error('VAO is null.');

        this.rectangleProgramData = { program, vao };
    }

    public draw() {
        if (!this.gl) throw new Error('gl is null.');

        this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

        this.sprites.forEach(({ texture, renderColor }) => {
            if (!this.gl) throw new Error('gl is null.');
            if (!this.programData) throw new Error('Program is null');

            this.gl.useProgram(this.programData.program);
            this.gl.activeTexture(this.gl.TEXTURE0);
            this.gl.bindTexture(this.gl.TEXTURE_2D, texture);

            const uTextureLocation = this.gl.getUniformLocation(this.programData.program, 'uMask');
            this.gl.uniform1i(uTextureLocation, 0);

            const uColorLocation = this.gl.getUniformLocation(this.programData.program, 'uColor');
            this.gl.uniform4fv(uColorLocation, new Float32Array(renderColor));

            this.gl.bindVertexArray(this.programData.vao);
            this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
            this.gl.bindVertexArray(null);
        });

        this.sprites.forEach(({ texture, selectOutlineWidth, hoverOutlineWidth }, index) => {
            const outline = Math.max(selectOutlineWidth, hoverOutlineWidth);
            if (!outline) return;

            if (!this.gl) throw new Error('gl is null.');
            if (!this.outlineProgramData) throw new Error('Outline program is null');

            if (!texture) throw new Error(`Texture ${index} doesn't exist.`);

            this.gl.useProgram(this.outlineProgramData.program);
            this.gl.activeTexture(this.gl.TEXTURE0);
            this.gl.bindTexture(this.gl.TEXTURE_2D, texture);

            const uTextureLocation = this.gl.getUniformLocation(this.outlineProgramData.program, 'uMask');
            this.gl.uniform1i(uTextureLocation, 0);

            const uOutlineColorLocation = this.gl.getUniformLocation(this.outlineProgramData.program, 'uOutlineColor');
            this.gl.uniform4fv(uOutlineColorLocation, new Float32Array([0, 0.35, 1, 1]));

            const uWidthLocation = this.gl.getUniformLocation(this.outlineProgramData.program, 'uWidth');
            this.gl.uniform1i(uWidthLocation, outline);

            this.gl.bindVertexArray(this.outlineProgramData.vao);
            this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
            this.gl.bindVertexArray(null);
        });

        if (this.frame) {
            if (!this.gl) throw new Error('gl is null.');
            if (!this.rectangleProgramData) throw new Error('Rectangle program is null');

            this.gl.useProgram(this.rectangleProgramData.program);

            this.gl.bindVertexArray(this.rectangleProgramData.vao);

            const positionBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);

            const position = [
                this.frame.value[0], this.frame.value[3],
                this.frame.value[2], this.frame.value[3],
                this.frame.value[2], this.frame.value[1],
                this.frame.value[0], this.frame.value[1],
            ]
                .map((value, index) => index % 2 ? value / this.height : value / this.width)
                .map((value, index) => index % 2 ? 1 - value : value);

            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(position), this.gl.STATIC_DRAW);
            const positionAttribLocation = this.gl.getAttribLocation(this.rectangleProgramData.program, 'aPosition');
            this.gl.enableVertexAttribArray(positionAttribLocation);
            this.gl.vertexAttribPointer(positionAttribLocation, 2, this.gl.FLOAT, false, 0, 0);

            this.gl.drawArrays(this.gl.LINE_LOOP, 0, 4);
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
            this.gl.deleteBuffer(positionBuffer);
            this.gl.bindVertexArray(null);
        }
    }

    public updateSprite(sprite: Sprite) {
        if (!this.gl) throw new Error('gl is null.');

        this.gl.bindTexture(this.gl.TEXTURE_2D, sprite.texture);
        const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
        this.gl.texSubImage2D(this.gl.TEXTURE_2D, 0, 0, 0, side, side, this.gl.RGBA, this.gl.UNSIGNED_BYTE, sprite.mask);
    }

    public deleteSprite(sprite: Sprite) {
        if (!this.gl) throw new Error('gl is null.');

        this.gl.bindTexture(this.gl.TEXTURE_2D, sprite.texture);
        this.gl.deleteTexture(sprite.texture);
        this.gl.bindTexture(this.gl.TEXTURE_2D, null);
    }

    public addSprite(sprite: Sprite) {
        if (!this.gl) throw new Error('gl is null.');

        sprite.texture = this.gl.createTexture();

        this.gl.bindTexture(this.gl.TEXTURE_2D, sprite.texture);
        const side = Math.ceil(Math.sqrt(this.width * this.height / 32));
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, side, side, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, sprite.mask);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
    }
}
