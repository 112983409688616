export const vertexShaderSource =
    `#version 300 es
    in vec4 aPosition;
    in vec2 aTexCoord;
    
    out vec2 vTexCoord;
    
    void main() {
        gl_Position = aPosition;
        vTexCoord = aTexCoord;
    }
`;

export const fragmentShaderSource =
    `#version 300 es
    precision lowp float;
    
    in vec2 vTexCoord;
    uniform ivec2 uCanvasSize;
    uniform vec2 uTexSize;
    uniform vec4 uColor;
    uniform sampler2D uMask;
    
    out vec4 fragColor;
    
    void main() {
        ivec2 pixelCoord = ivec2(vTexCoord * vec2(uCanvasSize)); // Преобразование текстурных координат в целочисленные пиксельные координаты
    
        // Индекс пикселя в текстуре
        int index = pixelCoord.y * uCanvasSize.x + pixelCoord.x;
        
        // Индекс в текстуре
        int textIndex = index / 32;
    
        // Координаты текстуры
        float tx = float(textIndex % int(uTexSize.x)) / uTexSize.x;
        float ty = float(textIndex / int(uTexSize.x)) / uTexSize.y;
        ivec2 texelCoord = ivec2(round(vec2(tx, ty) * uTexSize));
        vec4 pixel = texelFetch(uMask, texelCoord, 0);
    
        // Определяем позицию бита
        int bitPosition = index % 32;
        int value;
        if (bitPosition < 8) {
            value = int(pixel.r * 255.0);
        } else if (bitPosition < 16) {
            value = int(pixel.g * 255.0);
            bitPosition -= 8;
        } else if (bitPosition < 24) {
            value = int(pixel.b * 255.0);
            bitPosition -= 16;
        } else {
            value = int(pixel.a * 255.0);
            bitPosition -= 24;
        }
    
        // Определение бита
        int mask = 1 << bitPosition;
        int bitValue = (value & mask) >> bitPosition;
    
        if (bitValue == 1) {
            fragColor = uColor / 255.0;
        } else {
            discard;
        }
    }
`;

export const outlineFragmentShaderSource =
    `#version 300 es
    precision lowp float;
    
    in vec2 vTexCoord;
    uniform ivec2 uCanvasSize;
    uniform vec2 uTexSize;
    uniform int uWidth;
    uniform vec4 uOutlineColor;
    uniform sampler2D uMask;
    
    out vec4 fragColor;
    
    // Функция для проверки наличия маски в пикселе
    int isMasked(ivec2 pixelCoord) {
        int index = pixelCoord.y * uCanvasSize.x + pixelCoord.x;
        int textIndex = index / 32;
    
        float tx = float(textIndex % int(uTexSize.x)) / uTexSize.x;
        float ty = float(textIndex / int(uTexSize.x)) / uTexSize.y;
        ivec2 texelCoord = ivec2(round(vec2(tx, ty) * uTexSize));
        vec4 pixel = texelFetch(uMask, texelCoord, 0);
    
        int bitPosition = index % 32;
        int value;
        if (bitPosition < 8) {
            value = int(pixel.r * 255.0);
        } else if (bitPosition < 16) {
            value = int(pixel.g * 255.0);
            bitPosition -= 8;
        } else if (bitPosition < 24) {
            value = int(pixel.b * 255.0);
            bitPosition -= 16;
        } else {
            value = int(pixel.a * 255.0);
            bitPosition -= 24;
        }
    
        int mask = 1 << bitPosition;
        return (value & mask) >> bitPosition;
    }
    
    void main() {
        ivec2 pixelCoord = ivec2(vTexCoord * vec2(uCanvasSize));
    
        // Проверяем, что текущий пиксель находится внутри маски
        bool isCurrentMasked = isMasked(pixelCoord) == 1;
        
        // Проверяем, есть ли хотя бы один пиксель-сосед с маской (в радиусе 3 пикселей)
        bool isOutline = false;
        if (!isCurrentMasked) {
            for (int x = uWidth * -1; x <= uWidth; x++) {
                for (int y = uWidth * -1; y <= uWidth; y++) {
                    if (x == 0 && y == 0) continue; // Пропускаем текущий пиксель
                    ivec2 neighborCoord = pixelCoord + ivec2(x, y);
                    if (neighborCoord.x >= 0 && neighborCoord.x < uCanvasSize.x &&
                        neighborCoord.y >= 0 && neighborCoord.y < uCanvasSize.y) {
                        if (isMasked(neighborCoord) == 1) {
                            isOutline = true;
                            break;
                        }
                    }
                }
                if (isOutline) break;
            }
        }
    
        // Если пиксель на границе маски, рисуем контур
        if (isOutline) {
            fragColor = uOutlineColor;
        } else {
            discard; // Пропускаем пиксели, не являющиеся частью контура
        }
    }
`;

export const rectangleVertexShaderSource =
    `#version 300 es
    in vec4 aPosition;

    void main() {
      gl_Position = aPosition * 2.0 - 1.0;
    }
`;

export const rectangleFragmentShaderSource =
    `#version 300 es
    precision mediump float;

    out vec4 fragColor;

    void main() {
        fragColor = vec4(0.5, 0.5, 0.5, 0.8);
    }
`;
