import { CoordinatesI } from "services/useElementCoordinates";

export interface HintsPositionI {
  topHint: number;
  leftHint: number;
  topExHint: number;
  leftExHint: number;
}

export interface PositionI {
  top: string | number;
  left: string | number;
}

export function getRightHintPosition(
  elementId: string,
  coordinates: CoordinatesI
): HintsPositionI {
  switch (elementId) {
    case "select":
    case "move": {
      return {
        topHint: coordinates.top + coordinates.height / 2.18,
        leftHint: coordinates.right - 5,
        topExHint: coordinates.top + coordinates.height / 2.18,
        leftExHint: coordinates.right - 5,
      };
    }
    case "left-bar__icon5":
    case "left-bar__icon6": {
      return {
        topHint: window.innerHeight - coordinates.bottom,
        leftHint: coordinates.right - 5,
        topExHint: window.innerHeight - coordinates.bottom,
        leftExHint: coordinates.right - 5,
      };
    }

    default: {
      return {
        topHint: coordinates.top,
        leftHint: coordinates.right - 5,
        topExHint: coordinates.top,
        leftExHint: coordinates.right - 5,
      };
    }
  }
}

export function getBottomHintPosition(
  elementId: string,
  coordinates: CoordinatesI,
  hintSize: { height: number; width: number },
  extendedHintSize: { height: number; width: number }
): HintsPositionI {
  switch (elementId) {
    case "edit_group": {
      return {
        topHint: coordinates.bottom - 1,
        leftHint: coordinates.left - hintSize.width / 6 + 6,
        topExHint: coordinates.bottom,
        leftExHint:
          coordinates.left - extendedHintSize.width / 2 + coordinates.width / 2,
      };
    }
    case "ungroup": {
      return {
        topHint: coordinates.bottom,
        leftHint: coordinates.left - coordinates.width / 1.2 + 2,
        topExHint: coordinates.bottom,
        leftExHint:
          coordinates.left - extendedHintSize.width / 2 + coordinates.width / 2,
      };
    }
    case "join": {
      return {
        topHint: coordinates.bottom - 1,
        leftHint: coordinates.left + 10,
        topExHint: coordinates.bottom - 1,
        leftExHint:
          coordinates.left - extendedHintSize.width / 2 + coordinates.width / 2,
      };
    }
    case "delete": {
      return {
        topHint: coordinates.bottom,
        leftHint: coordinates.left - hintSize.width / 4,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "list__head_add": {
      return {
        topHint: coordinates.bottom,
        leftHint: coordinates.left - 32,
        topExHint: coordinates.bottom,
        leftExHint: coordinates.left,
      };
    }
    case "checkbox": {
      return {
        topHint: coordinates.bottom,
        leftHint: coordinates.left,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "research_demo": {
      return {
        topHint: coordinates.bottom,
        leftHint: coordinates.left - 10,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "create_group": {
      return {
        topHint: coordinates.bottom,
        leftHint: coordinates.left - 15,
        topExHint: coordinates.bottom,
        leftExHint: coordinates.left,
      };
    }
    case "header_home": {
      return {
        topHint: coordinates.bottom,
        leftHint: coordinates.left + 3,
        topExHint: coordinates.bottom,
        leftExHint: coordinates.left,
      };
    }
    case "undefine": {
      return {
        topHint: coordinates.bottom,
        leftHint: coordinates.left - coordinates.width / 1.3 + 2,
        topExHint: coordinates.bottom,
        leftExHint:
          coordinates.left - extendedHintSize.width / 2 + coordinates.width / 2,
      };
    }
    case "account_photo-info": {
      return {
        topHint: coordinates.bottom + 4,
        leftHint: coordinates.left - hintSize.width / 2 + coordinates.width / 2,
        topExHint: coordinates.bottom,
        leftExHint: coordinates.left,
      };
    }
    case "open_prototype-icon": {
      return {
        topHint: coordinates.bottom + 4,
        leftHint: coordinates.left - hintSize.width / 2 + coordinates.width / 2,
        topExHint: coordinates.bottom,
        leftExHint: coordinates.left,
      };
    }

    case "upload__register": {
      return {
        topHint: coordinates.bottom + 4,
        leftHint: coordinates.left - hintSize.width / 2 + coordinates.width / 2,
        topExHint: coordinates.bottom,
        leftExHint: coordinates.left,
      };
    }
    case "upload__header-state-note": {
      return {
        topHint: coordinates.bottom + 4,
        leftHint: coordinates.left - hintSize.width / 2 + coordinates.width / 2,
        topExHint: coordinates.bottom,
        leftExHint: coordinates.left,
      };
    }

    default: {
      return {
        topHint: coordinates.bottom,
        leftHint: coordinates.left - coordinates.width / 2,
        topExHint: coordinates.bottom,
        leftExHint: coordinates.left,
      };
    }
  }
}

export function getTopHintPosition(
  elementId: string,
  coordinates: CoordinatesI,
  hintSize: { height: number; width: number },
  extendedHintSize: { height: number; width: number }
): HintsPositionI {
  switch (elementId) {
    case "original_switcher": {
      return {
        topHint: coordinates.top - coordinates.height - 26,
        leftHint: coordinates.left + hintSize.width / 4 + 3,
        topExHint: coordinates.top - extendedHintSize.height - 25,
        leftExHint: coordinates.left - extendedHintSize.width / 2 + coordinates.width / 2,
      };
    }
    case "undefined-switcher": {
      return {
        topHint: coordinates.top - coordinates.height - 26,
        leftHint: coordinates.left,
        topExHint: coordinates.top - extendedHintSize.height - coordinates.height - 16,
        leftExHint: coordinates.left - extendedHintSize.width / 2 + coordinates.width / 2,
      };
    }
    case "zoom_in": {
      return {
        topHint: coordinates.top - coordinates.height,
        leftHint: coordinates.left - hintSize.width / 4,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "zoom_out": {
      return {
        topHint: coordinates.top - coordinates.height,
        leftHint: coordinates.left + hintSize.width / 4,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "zoom_fit": {
      return {
        topHint: coordinates.top - coordinates.height,
        leftHint: coordinates.left - 5,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "home_create-info": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left - hintSize.width / 2 + coordinates.width / 2,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "balance-info": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left - hintSize.width / 2 + coordinates.width / 2,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "scenery-info": {
      return {
        topHint: coordinates.top - hintSize.height,
        leftHint: coordinates.left - hintSize.width / 2 + coordinates.width / 2,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "help-scroll_down": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left - hintSize.width / 2 + coordinates.width / 2,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "help": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left - 5,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "collapse": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left - hintSize.width + coordinates.width,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "launch_tutorial-info": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left + hintSize.width / 4 + 4,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "segment_alarm-icon_": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left - hintSize.width / 2 + 9,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "segment_include-bttn_": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left - coordinates.width + 4,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    default: {
      return {
        topHint: coordinates.top,
        leftHint: coordinates.left + 34,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "attachment_segment_":
    case "attachment_group-segment-element_":
    case "attachment_group_":
    case "attachment_editting-group_": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left,
        topExHint: 0,
        leftExHint: 0,
      };
    }
    case "color_circle_":
    case "color_circle-group_segment_": {
      return {
        topHint: coordinates.top - hintSize.height - 4,
        leftHint: coordinates.left - hintSize.width / 2 + 7,
        topExHint: coordinates.top - extendedHintSize.height - coordinates.height - 16,
        leftExHint: coordinates.left - extendedHintSize.width / 2 + coordinates.width / 2,
      };
    }
  }
}

export function getHintsPosition(
  elementId: string,
  position: string,
  coordinates: CoordinatesI,
  hintSize: { height: number; width: number },
  extendedHintSize: { height: number; width: number }
): HintsPositionI {
  switch (position) {
    case "right":
      return getRightHintPosition(elementId, coordinates);
    case "bottom":
      return getBottomHintPosition(
        elementId,
        coordinates,
        hintSize,
        extendedHintSize
      );
    case "top":
      return getTopHintPosition(
        elementId,
        coordinates,
        hintSize,
        extendedHintSize
      );
    default:
      return { topHint: 0, leftHint: 0, topExHint: 0, leftExHint: 0 };
  }
}

export interface ExtendedHintI {
  element: string;
  title: string;
  description: string[];
}

export const bigTooltipsDescr: ExtendedHintI[] = [
  {
    element: "indicate",
    title: "Create/edit mask indicating object you want to define",
    description: [
      "Launch a mask creation/edit session to define an object using an AI-enhanced toolkit. Generalize objects as necessary. For instance, you can define a window as a single object or break it down into components like the frame, handle and glass.",
    ],
  },

  {
    element: "frame to add",
    title: "Frame any object intended for descritpion with rectangle",
    description: [
      "Frame anything to include in the object mask. Roughly drag the frame over the object previewing results. Redo to override. Only one frame per mask is applicable. Consider the frame as the main object identification tool.",
    ],
  },
  {
    element: "point to add",
    title: "Hover and click anything to add to the mask",
    description: [
      `Hover to preview and click anything to add to object mask. Think of it as pointing with your finger and saying, "this is it."`,
    ],
  },
  {
    element: "point to exclude",
    title: "Hover and click anything to exclude from the mask",
    description: [
      "Hover to preview and click anything to exclude from the object mask. ",
    ],
  },
  {
    element: "create group",
    title: "Create a group of similar objects to describe together",
    description: [
      "Click create group and select similar objects in the viewport or in the table.",
      "To select in viewport just click masks. To exclude from the group shift-click.",
      "Add or exclude objects directly in the table dragging rows in and out of the group.",
      "If an object you attempt to add to the group already have description or attachments decide whether to include it’s description in the group or not.",
      "Approve when ready or cancel. Note that you can always return to edit the group.",
    ],
  },
  {
    element: "edit group",
    title: "Group to define together",
    description: [
      "In many cases, your image may contain identical objects. Picture similar windows or chairs around the table.",
      "To define identical objects together, group them. During the rendering process group definition will be applied to every object in the group.",
    ],
  },
  {
    element: "ungroup",
    title: "Ungroup to define separately",
    description: [
      "Select group and click ungroup to define objects separately. When you ungroup every group element inherit group definition.",
    ],
  },
  {
    element: "join",
    title: "Join masks",
    description: [
      "To join masks together, select the desired masks and click join masks. Object definitions will be combined.",
    ],
  },
  {
    element: "lock",
    title: "Lock mask outlines",
    description: [
      "When you indicate new objects in the viewport, the AI may try to refine the outlines of every present object mask, unless they are locked. To prevent undesired changes, make sure to lock the mask of any object that you want to keep untouched.",
    ],
  },
  {
    element: "delete",
    title: "Select and delete",
    description: [""],
  },
  {
    element: "transparency",
    title: "Mark transparent objects",
    description: [
      "Unless the object is marked as transparent, you cannot indicate anything within the outlines of its mask. Therefore, if you need to indicate something behind a window glass, mark the glass as transparent in the table.",
    ],
  },
  {
    element: "attach",
    title: "Attach anything",
    description: [
      "Attach anything to enhance object description. Choose from a library or upload manually.",
    ],
  },
  {
    element: "label selected",
    title: "Switch on to see labels",
    description: [
      "Switch on to see labels for last selected object. Edit object description directly within a label.",
    ],
  },
  {
    element: "select",
    title: "Select masks",
    description: [
      "Use “select” to choose masks in the viewport.",
      "Hold Ctrl (or Command on Mac) when clicking or making frame to add masks to the current selection. Hold Shift when clicking to remove.",
      "Draw the selection frame from left to right to select only the masks fully enclosed by the rectangle. Draw from right to left to also include masks intersected by the rectangle.",
    ],
  },
  {
    element: "hand tool",
    title: "Hand tool",
    description: [
      "Enable hand tool to drag your image in the viewport.",
      "Press and hold the spacebar or scroll wheel to temporarily enable hand tool.",
    ],
  },
  {
    element: "undo/redo",
    title: "Undo/redo",
    description: [
      "Use the undo and redo buttons to fix mistakes or revert changes.",
      "There is a limit of X undo actions you can perform.",
    ],
  },
  {
    element: "eraser",
    title: "Eraser",
    description: [
      "Refine the mask with eraser. Use the right bracket ] to increase and the left bracket [ to decrease the eraser size.",
    ],
  },
  {
    element: "brush",
    title: "Brush",
    description: [
      "Draw the mask with the brush. Use the right bracket ] to increase and the left bracket [ to decrease the brush size.",
    ],
  },
  {
    element: "undefined-switcher",
    title: "Show undefined objects",
    description: [
      "Toggle show undefined to highlight image parts without definitions. The table will filter accordingly. To refresh the filter, toggle it off and on again.",
    ],
  },
  {
    element: "hide masks",
    title: "Hide masks",
    description: ["Toggle hide masks to view the image without masks."],
  },

  {
    element: "undefine",
    title: "Discard all object definitions",
    description: [
      "To remove all definitions including text and attachments, select designated rows and click undefine.",
    ],
  },
  {
    element: "color circle",
    title: "Tune mask color",
    description: [
      "Click to refresh the mask color. Repeat until satisfied.",
    ],
  },
];


export function getFlexDirection(arrowPosition: string | undefined) {
  switch (arrowPosition) {
    case "right":
      return "row";
    case "top":
      return "column-reverse";
    case "bottom":
      return "column";
    default:
      return "row";
  }
}
