import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import { openModal, ModalType } from 'entities/modal';
import { $email } from 'entities/user';
import { LeftSidePanel, setLeftSidePanel } from 'entities/leftSidePanel';
import { $file, $manager } from 'entities/sketch';
import { goTo, RoutePath } from 'entities/router';
import { useManager } from 'entities/sketch/Manager';
import { setHint } from 'entities/hint';
import Select from 'Components/Select';
import { copySketchFx } from 'entities/sketches';
import { updateSketchName } from 'services/sketch';
import { useHotKey } from 'hooks';
import './Header.scss';


interface HeaderProps {
    type?: 'home' | 'sketch' | 'upload';
    onLogoClick?: () => void;
}

const sketchMenuOptions = [
    { key: 'CREATE NEW', value: 'CREATE NEW' },
    { key: 'SAVE', value: 'SAVE' },
    { key: 'SAVE AS COPY', value: 'SAVE AS COPY' },
] as const;

export default function Header({ type = 'home', onLogoClick }: HeaderProps) {
    const email = useStore($email);
    const file = useStore($file);
    const [state, setState] = useState('Lol kek Cheburek');
    const [nameEdit, setNameEdit] = useState<boolean>(false)
    const manager = useStore($manager)


    useEffect(() => setState(''), [type]);

    const save = useCallback(() => {
        const manager = $manager.getState();
        if (!manager) throw new Error('Segment is not exist');
        setState(manager.name + ' (SAVING...)');
        manager.saveSketch().then(() => setState(` (SAVED ${new Date().toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })})`));
    }, []);

    return (
        <div className="upload_header">
            <div className="upload__left-header">
                {
                    type === 'sketch'
                        ?
                        <HeaderMenu setState={setState} />
                        :
                        <div className="upload__header-logo" onClick={onLogoClick} />
                }
                <div className={classNames({
                    'upload__header-home': true,
                    'upload__header-home_disabled': type === 'home'
                })}
                    onClick={() => {
                        if (type === 'upload' && !file) goTo(RoutePath.HOME)
                        else openModal({ type: ModalType.SKETCH_LEAVING })
                    }}>
                    <div id="header_home" className={classNames({ 'upload__header-home-icon': true, 'upload__header-home-icon_disabled': type === 'home' })}
                        onMouseEnter={() => setHint({ id: 'header_home' })}
                        onMouseLeave={() => setHint(null)} />
                </div>
                <div className="upload__header-state-container">
                    {type === 'sketch' && <div className="upload__header-state-note"
                        id='upload__header-state-note'
                        onMouseEnter={() => setHint({ id: 'upload__header-state-note' })}
                        onMouseLeave={() => setHint(null)}
                        onClick={save}><div className="upload__header-state-save-icon" />DEMO DOESN'T AUTOSAVE - SAVE FREQUENTLY</div>}
                    <div className="upload__header-state" onDoubleClick={() => setNameEdit(true)}>
                        {nameEdit ? <NameInput cancel={() => setNameEdit(false)} /> : manager && <p>{manager.name + state}</p>}
                        <div />
                    </div>
                </div>
            </div>
            {!email ?
                <div className="sketch__header-register" onClick={() => setLeftSidePanel(LeftSidePanel.AUTH)}>sign in</div>
                :
                <div className="upload__register" onClick={() => setLeftSidePanel(LeftSidePanel.ACCOUNT)}
                    id='upload__register'
                    onMouseEnter={() => setHint({ id: "upload__register" })}
                    onMouseLeave={() => setHint(null)}>
                    <div className='upload_header_user-avatar'>
                        <img src={require('./icons/user_exaple.jpeg')} alt="user" />
                    </div>
                    {email}
                </div>
            }
        </div>
    );
}

type HeaderMenuProps = {
    setState: React.Dispatch<React.SetStateAction<string>>;
};

function HeaderMenu({ setState }: HeaderMenuProps) {
    const manager = useManager('self');

    const selectMenuOption = useCallback((value: typeof sketchMenuOptions[number]['value']) => {
        switch (value) {
            case 'CREATE NEW':
                setLeftSidePanel(LeftSidePanel.SAVE_BEFORE_CLOSING);
                break;
            case 'SAVE':
                setState(manager.name + ' (SAVING...)');
                manager.saveSketch().then(() => setState(manager.name + ` (SAVED ${new Date().toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })})`));
                break;
            case 'SAVE AS COPY':
                setState(manager.name + ' (SAVING...)');
                copySketchFx(manager.id).then(() => setState(manager.name + ` (SAVED ${new Date().toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })})`));;
                break;
            default:
                return console.error(value, 'is not implemented');
        }
    }, [manager, setState]);

    return (
        <Select items={sketchMenuOptions} value={undefined} setValue={selectMenuOption} className="upload__header-sketch-menu" />
    );
}

type NameInputProps = {
    cancel: () => void
};

function NameInput({ cancel }: NameInputProps) {
    const manager = useManager('self');

    const inputRef = useRef<HTMLInputElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const [value, setValue] = useState(manager.name);

    useEffect(() => {
        if (spanRef.current && inputRef.current) {
            const spanWidth = spanRef.current.offsetWidth;
            inputRef.current.style.width = `${spanWidth + 10}px`;
        }
    }, [value]);

    const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (value.trim() !== "") {
                manager.name = value;
                updateSketchName({ name: value, sketchId: manager.id });
            }
            cancel();
        }
    }, [cancel, manager, value]);

    useHotKey('Escape', cancel);

    return (
        <div className="settings_title-input">
            <span className="size-span" ref={spanRef}>{value}</span>
            <input
                value={value}
                onChange={e => setValue(e.target.value)}
                ref={inputRef}
                onKeyDown={handleKeyDown}
                maxLength={36}
                autoFocus={true}
                onBlur={cancel}
            />
        </div>
    );
}
